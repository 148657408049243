<template>
  <section id="surgery-information-files">
    <NoFilterResults
      v-if="!isLoading && !Object.keys(files).length"
      message="Nenhum arquivo adicionado, clique em 'Anexar arquivo'"
    />

    <div v-else-if="isLoading">
      <b-skeleton width="100%" height="63px" v-for="i in 5" :key="i" class="mb-2" />
    </div>

    <perfect-scrollbar v-else :options="{ suppressScrollX: true }">
      <FileList v-for="(file, index) in files" 
        :key="index" :file="file"
        @openDocumentFile="openDocumentFile"
      >
        <b-col>
          <div class="d-flex ml-auto">
            <Edit
              v-can="'FpPron7'"
              class="ml-auto icon edit mr-2"
              @click="editFile(file[0], file)"
            />
            <Delete
              v-can="'FpPron9'"
              class="icon"
              @click="confirmDelete(file[0].id)"
            />
          </div>
        </b-col>
      </FileList>
    </perfect-scrollbar>

    <EditDocumentModal
      :attendanceDocumentFile="file"
      :getAllDocs="getSurgeryInformationFiles"
    />
    <EditExamModal
      :attendanceExamFile="file"
      :getAllExams="getSurgeryInformationFiles"
    />
    <EditGroupFilesModal
      :patientId="patientId"
      :document="file"
      :grouped="documentGrouped"
      :getAllDocs="getSurgeryInformationFiles"
      :getAllExams="getSurgeryInformationFiles"
    />

    <DocumentModal :documents="selectedFile" />
  </section>
</template>

<script>
export default {
  name: 'Files',
  props: {
    patientId: {
      type: String,
      required: true
    },
    surgeryInformationId: String,
    needReloadFiles: Boolean,
    reloadFiles: Function,
    generalSheetId: String
  },
  components: {
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    FileList: () => import('@/components/General/FileList'),
    EditDocumentModal: () => import('@/components/General/EditDocumentModal'),
    EditExamModal: () => import('@/components/General/EditExamModal'),
    EditGroupFilesModal: () => import('@/components/General/EditGroupFilesModal'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    DocumentModal: () => import('@/components/General/DocumentModal')
  },
  data: () => ({
    files: [],
    file: {},
    selectedFile: [],
    documentGrouped: [],
    isLoading: false
  }),
  methods: {
    openDocumentFile(file) {
      this.selectedFile = file
      this.$bvModal.show('document-modal')
    },
    async getSurgeryInformationFiles() {
      this.isLoading = true
      try {
        const { data } = await this.api.getSurgeryInformationFiles(
          this.surgeryInformationId,
          this.generalSheetId
        )
        this.files = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.isLoading = false
      }
    },
    confirmDelete(id) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover esse arquivo das informações da cirurgia? Para removê-lo completamente você deve ir no prontuário do paciente.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteSurgeryInformationFileByProps(id)
          this.$emit('reload-files')
        }
      })
    },
    async deleteSurgeryInformationFileByProps(id) {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteSurgeryInformationFileByProps(
          this.surgeryInformationId,
          id
        )
        this.$toast.success('Arquivo removido com sucesso')
        this.removeFile(id)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    removeFile(id) {
      this.$delete(this.files, id)
    },
    editFile(file, fileArray) {
      this.file = file
      if (fileArray.length > 1) {
        this.documentGrouped = fileArray
        this.$bvModal.show('edit-groups-file-modal')
        return
      }
      this.openModal(file.distinct_type)
    },
    openModal(type) {
      const modalId = {
        doc: 'edit-document-modal',
        exam: 'edit-exam-modal'
      }[type]
      this.$bvModal.show(modalId)
    }
  },
  watch: {
    surgeryInformationId: {
      async handler(value) {
        if (!value) return
        await this.getSurgeryInformationFiles()
      },
      immediate: true
    },
    needReloadFiles: {
      async handler(value) {
        if (!value) return
        await this.getSurgeryInformationFiles()
        this.reloadFiles(false)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 20px;
  cursor: pointer;

  &.edit {
    stroke: var(--blue-500);
  }
}
</style>
